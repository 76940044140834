import { useRef, useCallback, useState } from 'react';

import style from './Contact.module.css';

import { InputGroup, Paper } from '../../shared/components';


import { Whatsapp } from '../../shared/services';

export const Contact = () => {

  const [requiredName, setRequiredName] = useState(false);
  const [requiredEmail, setRequiredEmail] = useState(false);
  const [requiredMessage, setRequiredMessage] = useState(false);

  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const messageInputRef = useRef<HTMLTextAreaElement>(null);

  const handleClickSubmit = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let whatsapp = new Whatsapp(process.env.REACT_APP_TELEPHONE!);
    let name = nameInputRef.current?.value;
    let email = emailInputRef.current?.value;
    let message = messageInputRef.current?.value;

    if (!name || !email || !message) {
      !name ? setRequiredName(true) : setRequiredName(false);
      !email ? setRequiredEmail(true) : setRequiredEmail(false);
      !message ? setRequiredMessage(true) : setRequiredMessage(false);

      return;
    }

    setRequiredName(false);
    setRequiredEmail(false);
    setRequiredMessage(false);

    whatsapp.sendMessage(`Nome: ${name}%0aEmail: ${email}%0a%0aMensagem: ${message}`);

  }, []);

  return (
    <div className={style.ContactContainer}>
      <Paper sx={{ minHeight: '40em' }}>
        <form>

          <h1 className={style.TitleContact}>Contato</h1>

          <InputGroup required={requiredName} label='Nome'>
            <input onKeyUp={() => setRequiredName(false)} ref={nameInputRef} type="text" name="name" className={style.FormInput} />
          </InputGroup>

          <InputGroup required={requiredEmail} label='Email'>
            <input onKeyUp={() => setRequiredEmail(false)} ref={emailInputRef} type="email" name="email" className={style.FormInput} />
          </InputGroup>

          <InputGroup required={requiredMessage} label='Mensagem'>
            <textarea onKeyUp={() => setRequiredMessage(false)} ref={messageInputRef} name="menssagem" rows={3} className={style.TextArea}></textarea>
          </InputGroup>

          <button onClick={handleClickSubmit} className={style.SendContact}>ENVIAR</button>

          <div className={style.SocialMedia}>
            <a href="https://www.instagram.com/dasaguasbj/" rel="noopener noreferrer" target="_blank"><img src="/assets/images/instagram.png" alt="instagram" /></a>
          </div>

        </form>
      </Paper>
    </div >
  )
}