import { useCallback, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Badge } from '../badge/Badge';
import { useCartContext } from '../../context';

import { CartItem } from './cart-item/CartItem';
import { EmptyCart } from './empty-cart/EmptyCart';

import style from './Cart.module.css';
import { Price } from '../price/Price';

export const Cart = () => {
  const { cart, totalItems, HTMLRef, showCartView, closeCartView, toggleCartView, getTotalPrice } = useCartContext();

  useEffect(() => {
    document.addEventListener('scroll', (event: Event) => {
      if (event.target) {
        if (document.documentElement.scrollTop > 30) {
          HTMLRef.current?.classList.add(style.FloatingCart);
        } else {
          HTMLRef.current?.classList.remove(style.FloatingCart);
        }
      }
    })
  }, [HTMLRef])

  useEffect(() => {
    const handleOutsideClick: EventListenerOrEventListenerObject = (ev: Event) => {
      if (HTMLRef.current && !HTMLRef.current.contains(ev.target as Node)) {
        closeCartView();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [closeCartView, HTMLRef]);

  const drawCartView = useCallback(() => {
    let productList: React.ReactElement[] = []

    Object.values(cart).map((cartItem, key) => productList.push(
      <CartItem product={cartItem.product} key={key} />
    ));

    return productList;
  }, [cart])

  const calcPrice = useCallback(() => {
    let totalPrice = getTotalPrice();

    return (
      <Price
        amount={totalPrice}
        currency={'R$'}
        centsStyle={{ fontSize: 22 }}
        currencyStyle={{ fontSize: 22 }}
      />
    )
  }, [getTotalPrice]);

  return (
    <div onClick={toggleCartView} ref={HTMLRef} className={style.Cart} >
      {totalItems > 0 && !showCartView && <Badge className={style.Badge} text={totalItems} />}
      <img className={style.CartButton} src={showCartView ? '/assets/images/close-cart.png' : '/assets/images/cart.png'} alt="cart" />
      <div onClick={ev => { ev.stopPropagation() }} className={style.CartView} style={{ display: showCartView ? 'block' : 'none' }}>
        {totalItems > 0 ? drawCartView() : <EmptyCart />}
        {
          totalItems > 0
          &&
          <div className={style.FinalCart}>
            <Link to='/checkout'>
              <button className={style.CheckoutButton}>Finalizar pedido</button>
            </Link>
            {calcPrice()}
          </div>
        }
      </div>
    </div>
  )
}