
import { ModalProvider, NotificationProvider, IdentificationProvider } from '../../context';

import { useEffect, } from 'react';
import { useCartContext } from '../../context';
import { useLocation } from 'react-router-dom';

export interface IAppController {
  children: React.ReactNode;
}


export const AppController: React.FC<IAppController> = ({ children }) => {

  const { setCartDispay, HTMLRef } = useCartContext();
  const location = useLocation();

  useEffect(() => {
    if (HTMLRef.current)
      location.pathname === '/checkout' ? setCartDispay('none') : setCartDispay('flex');
  }, [HTMLRef, location, setCartDispay]);
  return (
    <>
      <IdentificationProvider>
        <NotificationProvider>
          <ModalProvider>
            {children}
          </ModalProvider>
        </NotificationProvider>
      </IdentificationProvider>
    </>
  )
}