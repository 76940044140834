import style from './About.module.css';
import { Paper } from '../../shared/components';

export const About = () => {

  return (
    <div className={style.AboutContainer}>
      <Paper sx={{ minHeight: '40em' }}>
        <div>
          <h1 className={style.TitleAbout}>Sobre nós</h1>
          <div className={style.ThumbVideos}>
          </div>
          <div className={style.AboutContent}>
            <h3>Sobre a Ilha</h3>
            <p>Localizada na Baía de "Kirimurê" (o Grande Mar Interior), que foi traduzida nos versos da música de Jota Velloso e interpretada por Maria Bethânia, que resume sua essência no verso, “Kirimurê linda varanda de águas salgadas mansas... que mergulham dentro de mim”.
              <br /><br />
              Esse charmoso recanto foi batizado pelos índios de Patahyba Assu (Palmeira Grande), entretanto, foi rebatizada de Ilha do Bom Jesus dos Passos após a construção da igreja em 1776, que leva o nome do Santo da tradição católica. Atualmente, foi apelidada de “Bomja”, pelos que visitam e são cativados pelo povo acolhedor e por seus encantos naturais.
              <br /><br />
              Originalmente habitada por índios Tupinambás, possui ainda descendência portuguesa, holandesa e africana.  Habitantes, que hoje em razão dos laços parentais, sentem-se como uma grande família, que mantém as manifestações culturais e a gastronomia regional do lugar.
              <br /><br />
              Além de toda exuberância natural oferecida neste pacato paraíso escondido na Baia de Todos os Santos. A ilha inspira paz e  tranquilidade.  As ruas bucólica são muito charmosas e cheias de histórias, e a nossa gente é muito acolhedora.
              <br /><br />
              "Bomja" carrega a fama de Ilha Gastronômica. Afinal a ilha já ganhou 4 títulos do Panela de Bairro. E desses 4, 2 são da Chef Vanessa Soares.
              <br /><br />
            </p>
            <h3>Sobre a Chef Vanessa Soares</h3>
            <p>
              Neste cenário de beleza e acolhimento, a descendente de “nativo” Chef Vanessa Soares, comungava nas brincadeiras de criança, seja no banho de mar da ponte, captura ao caranguejo nos manguezais, coleta de caju nas matas fechadas ao caminhar pela Ilha dos Frades até a Ponta de Nossa Senhora, travessia de canoa para outras ilhotas próximas e finalmente os corriqueiros “cozinhados”.
              <br /><br />
              Daí surgiu a paixão pela gastronomia, ainda criança quando ia para a maré, pegar siri, mariscos e peixes, para se reunir nos quintais das casas com outras crianças e fazer os “cozinhados”. Prática esta que permanece até hoje nas reuniões com amigos, onde cada um leva o que tem em casa e se transforma em uma grande celebração a vida.
              <br /><br />
              A Chef Vanessa traz em sua proposta de revisitar a cozinha nativa, feita tradicionalmente na Baía de Todos os Santos. Com essa inspiração, ela tem em seu cardápio a mais fina culinária mediterrânea, baseada em peixes e frutos do mar, com o toque da rusticidade ancestral da BTS.
              <br /><br />
              Com raízes paternas fincadas na terra, Vanessa saiu muito cedo pra estudar na capital baiana, cursou Administração e se especializou em Gestão de Projetos, e retornou à ilha afim de fazer o que mais gosta na vida. Cozinhar e contar histórias. E após esse retorno ingressou no curso de Gastronomia e participou também de cursos voltados pra cozinha espanhola e portuguesa.
              <br /><br />
              Venceu por duas vezes o Programa Panela de Bairro (2019 e 2021 - TV Bahia). Pela primeira vez com a Moqueca de Fruta-pão com Fumeiro e camarão seco. Foi um sucesso. Após esse prêmio a Chef foi convidada pela Fundação Baía Viva a integrar o Centro Comercial e Gastronômico da Marina do Brito, inaugurado no final de 2019, e então nasceu lá o Restaurante das Águas.
              <br /><br />
              Em 2021 a Chef foi convidada novamente para participar do Programa, numa edição especial de aniversario. E dessa vez o prato escolhido foi a Caldeirada Nativa. Apresentamos uma releitura da Caldeirada Portuguesa feita com batatas, peixes e frutos do mar. E representou a Bahia no programa Sabores do Nordeste (2022 - TV Globo), com uma cozinha criativa e original, que mistura as técnicas da cozinha mediterrânea com as práticas indígenas, incorporando as tradições locais com suas misturas de sabores e saberes.
              <br /><br />
            </p>
            <h3>Sobre o Das Águas</h3>
            <p>
              O Restaurante Das Águas, surgiu no Verão de 2019, carregado dessa memória afetiva e  pesquisas aprofundadas, inserindo à nossa gastronomia regional ingredientes e técnicas do mundo que surpreendem o paladar dos frequentadores com releituras de pratos clássicos, acompanhados de drinques também criativos, que segue em seu restaurante o conceito de uma “cozinha nativa e mediterrânea".
              <br /><br />
              O grande atrativo do empreendimento é a atmosfera criada para os clientes. Os visitantes são recebidos com uma mesa posta, com músicas selecionadas, imerso à natureza dos manguezais e com bebidinhas e comidinhas muito bem elaboradas. Uma verdadeira vivência gastronômica.
              <br /><br />
              Durante o dia encontrarão uma cozinha Nativa com moquecas, peixes fritos, caldos e bobós.  A noite uma cozinha Mediterrânea com Pizzas artesanais, massas e lasanhas acompanhados com um bom vinho. Para que todos possam aproveitar a refeição, se deliciando ainda com o visual único, a boa conversa e sintam toda a energia e proteção da doce Rainha Iemanjá.
              <br /><br />
            </p>
            <h3>Serviços Oferecidos:</h3>
            <p>
              - "Refeição com hora marcada", com a pré reserva os clientes escolhem os pratos, informam o número de pessoas e o horário. Ao chegar ao restaurante, terão uma mesa reservada, muito bem arrumada, e em pouco tempo serão servidos, reduzindo assim o tempos de espera.
              <br /><br />
              - "Delivery", esse serviço funciona tanto em terra quanto no mar.
              <br /><br />
              - "Cozinha ao vivo" na qual preparamos pratos únicos e especiais na frente dos clientes. Como exemplo de pratos, paellas, feijoada de mariscos, baião do mar, risotos, entre outros. Esse evento além de trazer uma experiência sensorial, traz também a interatividade entre as pessoas.
              <br /><br />
              "Chef em Casa", na qual a Chef Vanessa Soares prepara almoços e jantares na casa dos clientes.
              <br /><br />
              - "Passeio de barco  entre as ilhas". Muitos clientes procuram por esse serviço, então estabelecemos parceria com a empresa Thiago Passeios ( 71. 99911-7042)- @thiagopasseiosidf - a fim de solucionarmos essa questão. Os meninos de Thiago pegam os visitantes no Terminal de Madre de Deus, passeiam entre 5 ou 6 praias e por fim almoçam no Das Águas.
              <br /><br />
            </p>
            <h3>Como chegar?</h3>
            <p>
              O Restaurante Das Águas está localizado na Marina do Brito, na Ilha de Bom Jesus dos Passos no qual possui um pier de atracação, bem em frente a enseada da Luiza (Ilha dos Frades).
            </p>
            <h3>Reservas</h3>
            <p>
              A reserva é realizada com a própria Chef Vanessa Soares através do whatsapp (71 99189-8713).
            </p>
          </div>
        </div>
      </Paper>
    </div>
  )
}