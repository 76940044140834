
import style from './CartItem.module.css'
import { useCartContext } from "../../../context";

import { ProductCartController } from '../../product-cart-controller/ProductCartController';
import { Price } from '../../';

export interface ICartItem {
  product: Product,
}

export const CartItem: React.FC<ICartItem> = ({ product }) => {
  const { removeAllByProductId, getSubTotal } = useCartContext();

  return (
    <div className={style.CartItem}>
      <img src={process.env.REACT_APP_API_URL + '/' + product.images[0].url} alt="" />
      <div className={style.CartItemDetails}>
        <div>
          <h3>{product.name}</h3>
          <Price amount={product.price || 0} currency={'R$'} className={style.Price} />
        </div>
        <div className={style.Controller}>
          <ProductCartController product={product} />
          <Price amount={getSubTotal(product.id)}
            currency={'R$'}
            className={style.Price}
            priceStyle={{ fontSize: 23 }}
            centsStyle={{ fontSize: 18 }}
            currencyStyle={{ fontSize: 18 }}
          />

        </div>
      </div>
      <div onClick={() => removeAllByProductId(product.id)} className={style.Trash}>
        <img src='/assets/images/trash.png' alt="" />
      </div>
    </div >
  )
}