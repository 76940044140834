import style from './TextField.module.css';

export interface ITextField extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  value?: string | number;
  defaultValue?: string;
  placeholder?: string;
  onChangeText?: (value: string) => void;
  onChangeNumber?: (value: number) => void;
  beforeOnChange?: () => void;
  multiLine?: boolean;
  className?: string;
  containerClassName?: string;
  required?: boolean;
  requiredText?: string;
  label?: string;
  sx?: React.CSSProperties;
  minRows?: number;
  maxRows?: number;
  width?: string;
  disabled?: boolean;
}

export const TextField: React.FC<ITextField> = ({ value, defaultValue, placeholder, onChangeText, onChangeNumber, beforeOnChange, type, multiLine, className, containerClassName, required, requiredText, label, sx, minRows, maxRows, width, disabled, ...rest }) => {



  const _onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    beforeOnChange && beforeOnChange();

    if (type === 'number' && onChangeNumber) {
      onChangeNumber(parseInt(event.target.value || '0'))

    }
    else
      if (onChangeText) {
        onChangeText(event.target.value);

      }
  }

  return (
    <div className={`${style.TextField} ${className || ''}`}>
      {label && <label className={style.Label}>{label}</label>}
      {
        multiLine ?
          <textarea {...rest} value={value} defaultValue={defaultValue} placeholder={placeholder} onChange={_onChange} style={{ width, ...sx }} className={`${style.TextArea} ${className || ''}`} required={required} max-rows={maxRows} min-rows={minRows} />
          :
          <input {...rest} type={type || 'text'} value={value} defaultValue={defaultValue} placeholder={placeholder} onChange={_onChange} style={{ width, ...sx }} className={`${style.Input} ${className || ''}`} required={required} />
      }
      {required && <span className={style.RequiredText}>{requiredText || 'Campo obrigatório'}</span>}
    </div>
  );
}