import { createContext, useCallback, useContext, useState } from 'react';
import { IdentificationForm, Modal, Paper } from '../components';

export interface IIdentificationContext {
  isOpen: boolean
  showIdentificationModal: (onCloseForm?: () => void) => void,
  closeIdentificationModal: () => void,
}

export interface IIdentificationProviderProps {
  children: React.ReactNode
}

const IdentificationContext = createContext({} as IIdentificationContext)

export const useIdentificationContext = () => useContext(IdentificationContext);


export const IdentificationProvider: React.FC<IIdentificationProviderProps> = ({ children }) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [onClose, setOnClose] = useState<() => void>();

  const showIdentificationModal = useCallback((onCloseForm?: () => void) => {
    setOnClose(() => onCloseForm)
    setIsOpen(true)
  }, []);

  const closeIdentificationModal = useCallback(() => {
    setIsOpen(false)
  }, []);

  return (
    <IdentificationContext.Provider value={{ showIdentificationModal, closeIdentificationModal, isOpen }}>
      <Modal isOpen={isOpen}>
        <Paper sx={{ position: 'relative' }}>
          <IdentificationForm onSubmit={onClose} closeIdentificationForm={closeIdentificationModal} />
        </Paper>
      </Modal>
      {children}
    </IdentificationContext.Provider>
  )

}