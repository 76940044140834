
import { useCallback, useState } from 'react';
import { CloseButton, TextField } from '..';
import style from './IdentificationForm.module.css';

import { useCartContext } from '../../context';
import { Whatsapp } from '../../services';
import { getStoreInfo } from '../../services/store.info';

export interface IIdenticationFormProps {
  onSubmit?: () => void;
  closeIdentificationForm?: () => void;
}

export const IdentificationForm: React.FC<IIdenticationFormProps> = ({ onSubmit, closeIdentificationForm }) => {

  const { setClientInfo, formattedOrderText, clearCart } = useCartContext();

  const [name, setName] = useState('');
  const [customersNumber, setCustomersNumber] = useState(0);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [requiredName, setRequiredName] = useState(false);
  const [nameError, setNameError] = useState<string>();
  const [requiredCustomersNumber, setRequiredCustomersNumber] = useState(false);
  const [requiredDate, setRequiredDate] = useState(false);
  const [requiredTime, setRequiredTime] = useState(false);

  const _checkFullName = useCallback(() => {
    if (!name) {
      setRequiredName(true);
      setNameError('Nome não pode ser vazio');
      return false;
    } else if (name.length < 8) {
      console.log(name)
      setNameError('Nome muito pequeno');
      setRequiredName(true);
      return false;
    } else if (name.split(' ').length < 2) {
      setNameError('Digite um nome completo');
      setRequiredName(true);
      return false;
    }
    return true
  }, [name]);


  const _onSubmit = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {

    const { whatsapp } = await getStoreInfo();
    let whatsappService = new Whatsapp(whatsapp!);
    let errorFlag = false;
    event.preventDefault();

    errorFlag = !_checkFullName();

    if (!customersNumber || !date || !time) {
      !customersNumber ? setRequiredCustomersNumber(true) : setRequiredCustomersNumber(false);
      !date ? setRequiredDate(true) : setRequiredDate(false);
      !time ? setRequiredTime(true) : setRequiredTime(false);
      errorFlag = true;
    }

    if (errorFlag) return;
    let _date = new Date(date);
    let formattedDate = `${_date.getDate()}/${_date.getMonth() + 1}/${_date.getFullYear()}`;

    setClientInfo(name, customersNumber, date);
    whatsappService.sendMessage(formattedOrderText + `%0a%0aNome: ${name}%0aNúmero de clientes: ${customersNumber}%0aData do evento: ${formattedDate}%0aHorário: ${time}%0a`, () => clearCart());
    closeIdentificationForm && closeIdentificationForm();
    onSubmit && onSubmit();
  }, [date, name, time, customersNumber, setClientInfo, _checkFullName, formattedOrderText, clearCart, onSubmit, closeIdentificationForm]);

  return (
    <div className={style.IdentificationFormContainer}>
      <CloseButton onClick={closeIdentificationForm} />
      <h1 className={style.Title}>Identifique-se</h1>
      <form className={style.IdentificationForm}>
        <TextField containerClassName={style.TextField} minLength={10} beforeOnChange={() => setRequiredName(false)} onChangeText={(value) => setName(value)} name='name' requiredText={nameError} required={requiredName} label='Nome Completo' />
        <TextField containerClassName={style.TextField} type='number' beforeOnChange={() => setRequiredCustomersNumber(false)} max={100} name='customers' required={requiredCustomersNumber} label='Número de clientes' onChangeNumber={(value) => setCustomersNumber(value)} />
        <TextField containerClassName={style.TextField} type='date' name='date' beforeOnChange={() => setRequiredDate(false)} required={requiredDate} onChangeText={(value) => setDate(value)} label='Data' />
        <TextField containerClassName={style.TextField} type='time' name='time' beforeOnChange={() => setRequiredDate(false)} required={requiredTime} onChangeText={(value) => setTime(value)} label='Horário' />

        <button onClick={_onSubmit} className={style.SubmitButton}>Finalizar</button>
      </form>
    </div>
  )
}

