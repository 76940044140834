import React, { Component } from 'react';

import style from './Notifications.module.css';

export type NotificationType = 'Success' | 'Info' | 'Warning' | 'Error';

export interface NotificationProps {
  type: NotificationType;
  title: string;
  description?: string;
  delay?: number;
  image?: string;
  sx?: React.CSSProperties;
  className?: string;
}

export interface NotificationState {
  notifications: NotificationProps[];
}

export const handleNotification = (notification: NotificationProps, ref: React.RefObject<NotificationContainer>) => {
  ref.current?.addNotification(notification);
};

export class NotificationContainer extends Component<{}, NotificationState> {
  state: NotificationState = {
    notifications: [],
  };

  addNotification = (notification: NotificationProps) => {
    this.setState((prevState) => ({
      notifications: [...prevState.notifications, notification],
    }));

    setTimeout(() => {
      this.removeNotification(notification);
    }, notification.delay || 3500);
  };

  removeNotification = (notification: NotificationProps) => {
    this.setState((prevState) => ({
      notifications: prevState.notifications.filter((n) => n !== notification),
    }));
  };

  render() {
    const { notifications } = this.state;
    
    return (
      <div className={style.NotificationContainer}>
        {notifications.map((notification, index) => (
          <div key={index} className={style.Notification + ' ' + style[`Notification${notification.type}`]}>
            {notification.image && <img src={notification.image} alt="Imagem da notificação" />}
            <div className={style.NotificationContent}>
              <h2>{notification.title}</h2>
              {notification.description && <p>{notification.description}</p>}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
