import { createContext, useState, useCallback, useContext, useRef, useEffect } from 'react';


export interface ICartItem {
  quantity: number,
  product: Product,
  observations?: string
}

export interface IClientInfo {
  name: string,
  consumersNumber: number,
  date: string
}

export interface ICartContext {
  cart: Record<string, ICartItem>,
  currency: string,
  totalItems: number,
  clientInfo: IClientInfo | null,
  showCartView: boolean,
  HTMLRef: React.RefObject<HTMLDivElement>,
  formattedOrderText: string,
  addProduct: (product: Product, callback?: () => void) => void,
  clearCart: () => void,
  closeCartView: () => void,
  getProductQuantity: (productId: number | string) => number,
  getSubTotal: (productId: number | string) => number,
  getTotalPrice: () => number,
  setClientInfo: (name: string, consumersNumber: number, date: string, callback?: () => void) => void,
  removeProduct: (productId: number | string) => void,
  removeAllByProductId: (productId: number | string) => void,
  setProductObservations: (productId: number | string, observations: string) => void,
  setCartDispay: (display: 'flex' | 'none') => void,
  toggleCartView: () => void,
}

export interface ICartProviderProps {
  children: React.ReactNode
}

const CartContext = createContext({} as ICartContext)

export const useCartContext = () => useContext(CartContext);


export const CartProvider: React.FC<ICartProviderProps> = ({ children }) => {

  const [cart, setCart] = useState<Record<string | number, ICartItem>>({});
  const currency = 'R$'
  const [totalItems, setTotalItems] = useState<number>(0);
  const [clientInfo, _setClientInfo] = useState<IClientInfo | null>(null);
  const [showCartView, setShowCartView] = useState<boolean>(false);
  const [formattedOrderText, setFormattedOrderText] = useState<string>('');

  const HTMLRef = useRef<HTMLDivElement>(null);


  const closeCartView = useCallback(() => setShowCartView(false), []);
  const toggleCartView = useCallback(() => setShowCartView(old => !old), []);

  const addProduct = useCallback((product: Product, callback?: () => void) => {
    if (Object.keys(cart).indexOf((product.id).toString()) !== -1) {
      setCart(old => ({
        ...old,
        ...{
          [product.id]: {
            ...old[product.id],
            quantity: old[product.id].quantity + 1
          }
        }
      }));
    }
    else {
      let newProduct: Record<string | number, ICartItem> = {
        [product['id']]:
        {
          quantity: 1,
          product: product
        }
      }
      setCart(old => ({ ...old, ...newProduct }))
    }
    callback && callback();
    setTotalItems(old => old + 1)
  }, [cart]);

  const clearCart = useCallback(() => {
    setCart({});
    setTotalItems(0);
  }, []);

  const removeProduct = useCallback((productId: string | number) => {
    if (!cart[productId]) return;
    if (cart[productId].quantity <= 1) {
      delete cart[productId];
    } else {
      setCart(old => ({
        ...old,
        ...{
          [productId]: {
            ...old[productId],
            quantity: old[productId].quantity - 1
          }
        }
      }));
    }
    setTotalItems(old => old - 1)
  }, [cart]);

  const getProductQuantity = useCallback((product: string | number) => {
    return (cart[product] ? cart[product].quantity : 0)
  }, [cart])

  const getSubTotal = useCallback((productId: number | string) => {
    return cart[productId] ? parseFloat((cart[productId].quantity * (cart[productId].product.price || 0)).toFixed(2)) : 0
  }, [cart]);

  const getTotalPrice = useCallback(() => {
    let totalPrice = 0
    Object.values(cart).map((cartItem, key) => {
      if (cartItem.product.price) {
        totalPrice += cartItem.product.price * cartItem.quantity
      }
      return key;
    })
    return parseFloat(totalPrice.toFixed(2));
  }, [cart]);

  const setCartDispay = useCallback((display: 'flex' | 'none') => {
    if (HTMLRef.current) {
      HTMLRef.current.style.display = display;
    }
  }, [HTMLRef])

  const setProductObservations = useCallback((productId: string | number, observations: string) => {
    if (cart[productId]) {
      setCart(old => ({
        ...old,
        ...{
          [productId]: {
            ...old[productId],
            observations
          }
        }
      }));
    }
  }, [cart])

  const setClientInfo = useCallback((name: string, customersNumber: number, date: string, callback?: () => void) => {
    _setClientInfo({
      name,
      consumersNumber: customersNumber,
      date
    });
  }, []);

  const formatPrice = (currency?: string, amount?: number): string => {
    return `${currency} ${(amount?.toFixed(2))?.replaceAll('.', ',') || 0}`
  };

  useEffect(() => {
    let message = '';

    Object.values(cart).map(({ product, observations, quantity }) => {
      return message += `${quantity}x ${product.name} - ${formatPrice(currency, product.price)}%0aSubtotal: ${formatPrice(currency, getSubTotal(product.id))} %0a${observations ? 'Observações: %0a' + observations.replaceAll('\n', '%0a') + '%0a' : ''}%0a`;
    })
    message += `Total: ${formatPrice(currency, getTotalPrice())}`;
    
    setFormattedOrderText(message);
  }, [cart, clientInfo, getTotalPrice, getSubTotal, currency]);

  const removeAllByProductId = useCallback((productId: string | number) => {
    if (cart[productId]) {
      let qtd = cart[productId].quantity;
      setTotalItems(old => old - qtd);
      delete cart[productId]
    }
  }, [cart])

  return (
    <CartContext.Provider value={
      {
        cart,
        HTMLRef,
        currency,
        totalItems,
        clientInfo,
        showCartView,
        formattedOrderText,
        addProduct,
        clearCart,
        closeCartView,
        getProductQuantity,
        getSubTotal,
        getTotalPrice,
        removeProduct,
        removeAllByProductId,
        setCartDispay,
        setClientInfo,
        setProductObservations,
        toggleCartView,
      }}>
      {children}
    </CartContext.Provider>
  )
} 