import { Routes, Route, Navigate } from 'react-router-dom';

import { Menu, About, Contact, Checkout } from '../pages';

export const AppRoutes = () =>
  <Routes>
    <Route path="/" element={<Menu />} />
    <Route path="/about" element={<About />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/checkout" element={<Checkout />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>;