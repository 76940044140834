import style from './CloseButton.module.css';

export interface ICloseButton {
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  sx?: React.CSSProperties;
  width?: number;
  height?: number;
}

export const CloseButton: React.FC<ICloseButton> = ({ children, sx, className, width, height, onClick }) => {
  return (
    <div onClick={onClick} className={`${style.CloseButton} ${className}`} style={{ ...sx, width, height }}>
      <span>{children || 'X'}</span>
    </div>
  )
}