import style from './Paper.module.css'

export interface IPaper {
    children: React.ReactNode,
    sx?: React.CSSProperties,
    className?: string
}

export const Paper: React.FC<IPaper> = ({ children, sx, className = '' }) => {
    return (
        <div style={{ ...sx }} className={`${style.PaperContainer} ${className}`}>{children}</div>
    )
}