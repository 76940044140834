import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './EmptyCart.module.css';

export const EmptyCart: React.FC = () => {

  const [timeLeft, setTimeLeft] = useState<number>(5);
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    if (timeLeft === 0) {
      navigate('/')
    }
  }, [timeLeft, navigate]);

  return (
    <div className={style.EmptyCartContainer}>
      <h2>Carrinho vazio</h2>
      <h3>Voltando para o ínicio em: {timeLeft} segundos</h3>
    </div>
  )
}