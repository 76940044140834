import { useCallback } from 'react';
import { useCartContext } from '../../context';
import style from './ProductCartController.module.css';


import { useNotificationContext } from '../../context';

export interface IProductCartController {
  product: Product,
  sx?: React.CSSProperties,
  className?: string,
}

export const ProductCartController: React.FC<IProductCartController> = ({ product, sx, className }) => {

  const { addNotification } = useNotificationContext();

  const { addProduct, removeProduct, getProductQuantity } = useCartContext();

  const handleAddProduct = useCallback((product: Product) => {
    addProduct(product);
    addNotification({
      type: 'Info',
      title: 'Carrinho atualizado',
    });
  }, [addProduct, addNotification]);

  return (
    <div className={style.ProductCartController}>
      <div className={style.RemoveButton} onClick={() => removeProduct(product.id)}>
        <img src='/assets/images/remove-button.png' alt="" />
      </div>
      <span>
        {getProductQuantity(product.id)}
      </span>
      <div className={style.AddButton} onClick={() => handleAddProduct(product)} >
        <img src='/assets/images/add-button.png' alt="" />
      </div>
    </div>
  )
}