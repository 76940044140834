
import style from './EmptyCart.module.css';

export const EmptyCart = () => {

  return(
    <div className={style.EmptyCart}>
      Carrinho Vazio
    </div>
  );
 }