import { useCallback } from "react"


export interface IFormattedPrice {
  amount: number | string,
  cents: number | string,
}

export interface IPriceProps extends IPrice {
  sx?: React.CSSProperties,
  className?: string,
  priceStyle?: React.CSSProperties,
  currencyStyle?: React.CSSProperties,
  centsStyle?: React.CSSProperties,
}

export const Price: React.FC<IPriceProps> = ({ amount, currency, className, sx, currencyStyle, priceStyle, centsStyle }) => {

  const formatPrice = useCallback((): IFormattedPrice => {
    return {
      amount: amount.toFixed(2).split('.')[0],
      cents: amount.toFixed(2).split('.')[1]
    }
  }, [amount])

  return (
    <div className={className} style={{ ...sx }}>
      <span style={{ ...currencyStyle }}>{currency}</span>
      <span style={{ ...priceStyle }}>{formatPrice().amount},</span>
      <span style={{ ...centsStyle }}>{formatPrice().cents}</span>
    </div>
  )
}
