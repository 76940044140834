
import style from './Badge.module.css'

export interface IBadge {
  text: string | number
  sx?: React.CSSProperties,
  className?: string
}

export const Badge: React.FC<IBadge> = ({ text, sx, className }) => {

  return (
    <span className={`${style.Badge} ${className}`} style={{ ...sx }}>
      {text}
    </span>
  )
}