
import { useCallback } from 'react';

import { CartItem, Paper, EmptyCart } from '../../shared/components';
import { useCartContext, useIdentificationContext } from '../../shared/context';

import style from './Checkout.module.css'

export const Checkout = () => {

  const { cart, totalItems, setProductObservations } = useCartContext();
  const { showIdentificationModal } = useIdentificationContext();

  const drawCartView = useCallback(() => {
    let productList: React.ReactElement[] = []

    Object.values(cart).map((cartItem, key) => productList.push(
      <div key={key} className={style.CartItemContainer}>
        <CartItem product={cartItem.product} key={key} />
        <textarea defaultValue={cartItem.observations} onChange={(ev) => { setProductObservations(cartItem.product.id, ev.target.value) }} className={style.Observations} placeholder={`Observações para ${cartItem.quantity} produto${cartItem.quantity > 1 ? 's' : ''}`}></textarea>
      </div >
    ));

    return productList;
  }, [cart, setProductObservations,])

  return (
    <div className={style.CheckoutContainer}>
      <Paper sx={{ flexDirection: 'column', justifyContent: 'start' }}>
        {totalItems > 0 ? drawCartView() : <EmptyCart />}
        {totalItems > 0 && <button className={style.CheckoutButton} onClick={() => showIdentificationModal()}>Continuar</button>}
      </Paper>

    </div>
  )
}
