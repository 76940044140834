
import { BrowserRouter } from 'react-router-dom';
import './App.css';

import { AppRoutes } from './routes';
import { AppController } from './shared/components';
import { Background, Header, Content, Footer } from './shared/layout';
import { CartProvider } from './shared/context';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <CartProvider>
          <AppController>
            <Header />
            <Content>
              <AppRoutes />
            </Content>
            <Footer />
            <Background />
          </AppController>
        </CartProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
