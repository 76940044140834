
import style from './InputGroup.module.css';

export interface IInputGroupProps {
  children: React.ReactNode;
  label?: string;
  required?: boolean;
  requiredMessage?: string;
  sx?: React.CSSProperties;
  className?: string;
}


export const InputGroup: React.FC<IInputGroupProps> = ({ children, label, required, sx, className, requiredMessage }) => {
  return (
    <div style={{ ...sx }} className={style.InputGroup} >
      <div className={style.Label}>{label}</div>
      {children}
      {required &&
        <div className={style.RequiredBox}>
          <span>{requiredMessage || 'Campo obrigatório'}</span>
        </div>
      }
    </div >
  )
}

