import { createContext, useState, useCallback, useContext } from 'react';
import { Modal, ProductDetails } from '../components';


export interface IModalContext {
  isOpen: boolean
  showModal: () => void,
  closeModal: () => void,
  setContent: (product: Product) => void
}

export interface IModalProviderProps {
  children: React.ReactNode
}

const ModalContext = createContext({} as IModalContext)

export const useModalContext = () => useContext(ModalContext);


export const ModalProvider: React.FC<IModalProviderProps> = ({ children }) => {

  const [product, setProduct] = useState<Product | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const showModal = useCallback(() => {
    setIsOpen(true)
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  const setContent = useCallback((product: Product) => {
    setProduct(product);
  }, [])

  return (
    <ModalContext.Provider value={{ showModal, closeModal, setContent, isOpen }}>
      <Modal isOpen={isOpen}>
        {product && <ProductDetails product={product} handleClose={closeModal} />}
      </Modal>
      {children}
    </ModalContext.Provider>
  )

}