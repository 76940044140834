import style from './CarouselArrow.module.css';


export interface ICarouselArrow {
  direction?: 'left' | 'right',
  onClick: () => void,
  className?: string
}

export const CarouselArrow: React.FC<ICarouselArrow> = ({ direction = 'left', onClick, className }) => {

  return (
    <div onClick={onClick} className={`${style.Arrow} ${direction === 'right' ? style.ArrowRight : ''} ${className}`}>
      <img src={`/assets/images/arrow-${direction}.png`} alt="" />
    </div>
  )
}

