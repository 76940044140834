export class Whatsapp {
  telephone: string

  constructor(telephone: string) {
    this.telephone = telephone;
  }

  async sendMessage(message: string, callback?: () => void) {
    message.replaceAll('\n', '%0a');
    callback && callback();
    window.open(`https://wa.me/${this.telephone}?text=${message}`);
  }
}