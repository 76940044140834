import { createContext, useCallback, useContext, createRef } from 'react';
import { NotificationContainer, NotificationProps } from '../components';


export interface INotificationContext {
  addNotification: (notification: NotificationProps) => void;
  NotificationContainerRef: React.RefObject<NotificationContainer>;
}

export interface INotificationProviderProps {
  children: React.ReactNode
}

const NotificationContext = createContext({} as INotificationContext)

export const useNotificationContext = () => useContext(NotificationContext);


export const NotificationProvider: React.FC<INotificationProviderProps> = ({ children }) => {
  const NotificationContainerRef = createRef<NotificationContainer>();

  const addNotification = useCallback((notification: NotificationProps) => {
    NotificationContainerRef.current?.addNotification(notification);
  }, [NotificationContainerRef]);

  return (
    <NotificationContext.Provider value={{ addNotification, NotificationContainerRef }}>
      <NotificationContainer ref={NotificationContainerRef} />
      {children}
    </NotificationContext.Provider>
  )

}