import { Carousel } from 'react-responsive-carousel';
import { CarouselArrow, CloseButton, Paper, Price } from '../';
import style from './ProductDetails.module.css';
import { ProductCartController } from '../product-cart-controller/ProductCartController';
import { useCartContext } from '../../context';

export interface IProductDetails {
  handleClose?: () => void,
  product: Product,
}

export const ProductDetails: React.FC<IProductDetails> = ({ handleClose, product }) => {

  const { getProductQuantity } = useCartContext()

  let totalPrice = product.price ? product.price * getProductQuantity(product.id) : 0

  const drawProductsImages = () => {
    let imageList: React.ReactElement[] = [];

    product.images.forEach((image) => {
      imageList.push(
        <img className={style.ProductDetailsImages} key={image.url} src={process.env.REACT_APP_API_URL + image.url} alt={product.name} />
      )
    })
    return imageList;
  }

  const createCarouselArrow = (direction?: 'left' | 'right') => {
    return (clickHandler: () => void, hasNext: boolean) => {
      return (hasNext && <CarouselArrow onClick={clickHandler} direction={direction} className={`${style.ModalArrow} ${direction === 'left' ? style.ModalArrowLeft : style.ModalArrowRight}`} />)
    }
  }

  return (
    <div className={style.ProductDetailsContainer}>
      <CloseButton className={style.CloseButton} onClick={handleClose} />

      <div className={style.ScrollableContainer}>
        <Paper className={style.Paper}>
          <Carousel
            preventMovementUntilSwipeScrollTolerance
            swipeScrollTolerance={70}
            emulateTouch
            autoPlay={false}
            children={drawProductsImages()}
            showIndicators={true}
            showStatus={false}
            renderArrowNext={createCarouselArrow('right')}
            renderArrowPrev={createCarouselArrow('left')}
            className={style.Carousel}
            width="100%"
          />
          <div className={style.ProductDetails}>
            <h2 className={style.Title}>{product.name}</h2>
            <h3 className={style.Price}>
              <i>
                <Price amount={product.price || 0} currency={'R$'} />
              </i>
            </h3>
            <span>{product.description}</span>
          </div>
          <div className={style.ProductDetailsCart}>
            <h3>Adicione ao carrinho</h3>
            <div className={style.CartControllerContainer}>
              <ProductCartController product={product} />

              {totalPrice > 0 && <Price amount={totalPrice} currency={'R$'} />}

            </div>
            <button className={style.ConfirmButton} onClick={handleClose}>Confirmar</button>
          </div>
        </Paper>
      </div>
    </div>
  )
}