
import style from './Content.module.css'

export interface IContent {
  children: React.ReactElement
}


export const Content: React.FC<IContent> = ({ children }) => {
  return (
    <div className={style.Content}>
      {children}
    </div>
  );
}
