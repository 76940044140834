import { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom'

import style from './Header.module.css';
import { Cart } from '../../components/cart/Cart';

export interface IMenuPath {
  path: string,
  title: string,
}

const menuItens: IMenuPath[] = [
  {
    path: '/menu',
    title: 'Menu',
  },
  {
    path: '/about',
    title: 'Sobre nós',
  },
  {
    path: '/contact',
    title: 'Contato',
  },
]

export const Header = () => {

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    menuItens.map((menu: IMenuPath, index) => menu.path === document.location.pathname && setSelectedIndex(index)
    )
  }, [])

  const handleMenuClick = useCallback((index: number): void => {
    setSelectedIndex(index);
  }, []);

  const drawMenu = useCallback(() => {
    let menuElements: React.ReactElement[] = [];
    menuItens.map((menu: IMenuPath, index) => menuElements.push(
      <Link onClick={() => handleMenuClick(index)} to={menu.path} key={index}>
        <span className={index === selectedIndex ? style.SelectedMenu : ''}>{menu.title}</span>
      </Link>
    ));
    return menuElements;
  }, [selectedIndex, handleMenuClick]);

  return (
    <div className={style.Header}>
      <nav>
        {drawMenu()}
        <Cart />
      </nav>
      <div className={style.Logo}>
        <img src="/assets/images/logo.png" alt="" />
      </div>
    </div>
  );
}