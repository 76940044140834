import { useEffect } from 'react';
import style from './Modal.module.css';

export interface IModal {
  isOpen: boolean,
  children: React.ReactNode,
  onOpen?: () => void,
  onClose?: () => void,
}

export const Modal: React.FC<IModal> = ({ children, isOpen = false, onOpen, onClose }) => {

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen()
    } else
      if (!isOpen && onClose) {
        onClose()
      }
  }, [onClose, onOpen, isOpen]);

  if (!isOpen) return null;

  return (
    <div className={style.ModalContainer}>
      {children}
    </div>
  )
}